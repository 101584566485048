/** @jsx jsx */
import { Button, Heading, jsx, Spinner } from "theme-ui";
import { Formik, Form } from "formik";
import { InputField } from "./input";
import { AppContext } from "../../context/app-context";
import { useContext, useEffect, useState } from "react";
import { SelectField } from "./select";
import { UploadFile } from "./upload-file";
import { navigate } from "gatsby";

interface FormProps {
  title: string;
  fields: any;
  jobOffers?: any;
}

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};

export function FormContainer({ title, fields, jobOffers }: FormProps) {
  const { locale, formRef, selectId, secureUrl, setSecureUrl } = useContext(
    AppContext
  );

  const inputs = fields.sort((a, b) =>
    a.order > b.order ? 1 : a.order < b.order ? -1 : 0
  );
  const initialState = jobOffers !== null ? { jobOffer: "" } : {};
  inputs.forEach((input) => Object.assign(initialState, { [input.name]: "" }));

  const [value, setValue] = useState("");
  const [fileName, setFileName] = useState("");

  useEffect(() => {
    if (selectId) {
      const value = jobOffers.find((option) => option.id === selectId);
      setValue(value.jobPosition);
    }
  }, [selectId]);

  const contactSubmit = (values, resetForm, setSubmitting) => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...values }),
    })
      .then(() => {
        resetForm();
        navigate("/thanks");
      })
      .catch(() => {
        alert("Error");
      })
      .finally(() => setSubmitting(false));
  };

  const handleSubmit = async (e, { setSubmitting, resetForm }) => {
    if (
      jobOffers &&
      e.job &&
      e.job.length > 2 &&
      e.job !== "Elige de la lista" &&
      secureUrl
    ) {
      const postBody = {
        availability: e.availability,
        city: e.city,
        dni: e.dni,
        email: e.email,
        experience: e.experience,
        job: e.job,
        lastName: e.lastName,
        name: e.name,
        phone: e.phone,
        secureUrl: secureUrl,
      };
      const response = await fetch("/.netlify/functions/applicationForm", {
        method: "POST",
        body: JSON.stringify(postBody),
      }).then((r) => r.json());
      if (response) {
        setSubmitting(false);
        navigate("/thanks");
      }
    }
    if (jobOffers && (e.job === null || e.job.length < 2)) {
      alert("Elige el trabajo");
    }
    if (jobOffers && secureUrl === null) {
      alert("Sube tu hoja de vida");
    }
    if (e.company && e.email && e.message && e.name && e.phone && e.position) {
      contactSubmit(
        {
          company: e.company,
          email: e.email,
          message: e.message,
          name: e.name,
          phone: e.phone,
          position: e.position,
        },
        resetForm,
        setSubmitting
      );
    }
  };

  const uploadFile = async (e) => {
    const files = e.target.files;
    if (files[0]) {
      setFileName(files[0].name);
    }
    const data = new FormData();
    data.append("file", files[0]);
    data.append("upload_preset", "praxisnet");
    const res = await fetch(
      "https://api.cloudinary.com/v1_1/praxisnet/image/upload",
      {
        method: "POST",
        body: data,
      }
    );
    const file = await res.json();
    if (file) {
      setSecureUrl(file.secure_url);
    }
  };

  return (
    <div sx={{ px: 3, py: 5 }} ref={formRef}>
      {jobOffers && (
        <Heading
          as="h2"
          sx={{
            textAlign: "center",
            mb: 4,
            variant: "text.title",
          }}
        >
          {title}
        </Heading>
      )}
      <Formik
        initialValues={initialState}
        onSubmit={(values, actions) => handleSubmit(values, actions)}
      >
        {({ isSubmitting }) => (
          <Form
            name="contact"
            data-netlify={true}
            sx={{ maxWidth: "512px", mx: "auto", mb: 5 }}
          >
            {jobOffers && (
              <SelectField
                name={"job"}
                label={locale === "es-PE" ? "Postular a:" : "Apply to:"}
                options={[...jobOffers]}
                required={true}
                value={value}
              />
            )}
            {inputs.map((input) => (
              <InputField
                name={input.name}
                type={input.type}
                label={input.label}
                key={input.id}
                inputType={input.inputType}
                required={true}
              />
            ))}
            {jobOffers && (
              <UploadFile
                uploadFile={uploadFile}
                active={secureUrl !== null}
                fileName={fileName}
              />
            )}
            <p sx={{ textAlign: "center" }}>
              <Button aria-label="submit" type="submit" disabled={isSubmitting}>
                {isSubmitting ? "Enviando" : "Enviar"}
              </Button>
            </p>
          </Form>
        )}
      </Formik>
    </div>
  );
}
