/**@jsx jsx */
import { graphql, useStaticQuery } from "gatsby";
import { Fragment, useContext } from "react";
import { Embed, jsx } from "theme-ui";
import { AppContext } from "../../context/app-context";

function Contact({ title, value }) {
  return (
    <Fragment>
      <strong>{title}</strong> <span>{value}</span>
    </Fragment>
  );
}

export function ContactInfo() {
  const { locale } = useContext(AppContext);
  const data = useStaticQuery(graphql`
    query {
      allContentfulSocialLinks(filter: { type: { eq: "contact" } }) {
        nodes {
          label
          contentful_id
        }
      }
    }
  `);
  const contactInfo = data.allContentfulSocialLinks.nodes;
  const phone = contactInfo.find(
    (item) => item.contentful_id === "2ydsY8voMrwuecYUaZJ5WI"
  );
  const email = contactInfo.find(
    (item) => item.contentful_id === "SCtLUl2jaRaaNaWL1ChZv"
  );
  const address = contactInfo.find(
    (item) => item.contentful_id === "6AU7epWX4RciI2cVyhC1nh"
  );

  return (
    <div
      sx={{
        display: "grid",
        gridTemplateColumns: ["auto"],
        gridAutoRows: ["min-content"],
        p: [null, 3],
      }}
    >
      <div sx={{ bg: "footer" }}>
        <Embed
          src={`https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3901.5976893167503!2d-77.00055728533204!3d-12.071177991450996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105c63ac90570ed%3A0x504c3ccfd00a0ae6!2sAvenida%20Agust%C3%ADn%20de%20la%20Rosa%20Toro%20201%2C%20San%20Luis%2015021!5e0!3m2!1ses-419!2spe!4v1605060364055!5m2!1ses-419!2spe`}
        />
        <div
          sx={{
            p: 3,
            display: "grid",
            gridTemplateColumns: "min-content 1fr",
            gap: 1,
            textAlign: "left",
          }}
        >
          <Contact
            title={locale === "es-PE" ? "Teléfono:" : "Phone:"}
            value={phone.label}
          />
          <Contact
            title={locale === "es-PE" ? "Email:" : "Email:"}
            value={email.label}
          />
          <Contact
            title={locale === "es-PE" ? "Dirección:" : "Address:"}
            value={address.label}
          />
        </div>
      </div>
    </div>
  );
}
