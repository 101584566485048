/**@jsx jsx*/
import { jsx } from "theme-ui";
import { graphql } from "gatsby";
import { useContext, useEffect } from "react";
import { Layout } from "../components/layout/main-layout";
import { AppContext } from "../context/app-context";
import { Hero } from "../components/distribution/hero";
import { FormContainer } from "../components/form/form";
import { ContactInfo } from "../components/distribution/contact-info";
import { Seo } from "../components/seo/seo";

export default function Contactus({ pageContext, data }) {
  /* LOCALES SET UP*/
  const { setLocale } = useContext(AppContext);
  const lang = pageContext.locale === "es-PE" ? "es" : "en";

  useEffect(() => {
    setLocale(pageContext.locale);
  }, []);

  const page = data.contentfulPage;
  const { title, slug, content, image, page_content } = page;

  const form = data.forms.nodes;

  return (
    <Layout>
      <Seo
        title={title}
        image={{ src: image.fluid.src, height: 300, width: 300 }}
        lang={lang}
      />
      <Hero
        image={image.fluid}
        slug={slug}
        title={title}
        description={content?.content}
      />
      <div
        sx={{
          display: ["grid"],
          gridTemplateColumns: ["auto", "1fr 1fr"],
          p: [0, 5],
        }}
      >
        <ContactInfo />
        <FormContainer title={form[0].title} fields={form[0].form_fields} />
      </div>
    </Layout>
  );
}

export const query = graphql`
  query($locale: String, $slug: String) {
    contentfulPage(node_locale: { eq: $locale }, slug: { eq: $slug }) {
      title
      slug
      content {
        content
      }
      image {
        fluid(maxWidth: 2500, quality: 100) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      page_content {
        title
        content {
          json
        }
      }
    }

    forms: allContentfulForm(
      filter: {
        contentful_id: { eq: "71Bpk0vkklDW0reEXWGoRk" }
        node_locale: { eq: $locale }
      }
    ) {
      nodes {
        title
        form_fields {
          id
          type
          name
          label
          inputType
          order
        }
      }
    }
  }
`;
